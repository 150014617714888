import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthUriService} from './auth-uri.service';
import {configKeys} from '../../../config-keys';
import {CloudTranslationPayload, CloudTranslationResponse, CloudTranslations} from '../../../types';


@Injectable()
export class CloudTranslationApiService {

  constructor(
    private http: HttpClient,
    private authUriService: AuthUriService
  ) {}

  /**
   * Translates text into multiple languages
   *
   * @param payload - Translation params
   * @returns Promise<CloudTranslations> with translation response
   */
  public translate(payload: CloudTranslationPayload): Promise<CloudTranslations> {
    const basePath = this.authUriService.buildUrl(configKeys.CLOUD_TRANSLATION_URI_CONFIG_KEY, '/translate');
    const headers = this.authUriService.getAuthHeaders();

    const isDataUrl = payload.text.match(/^data:image/);
    const isUrl = payload.text.match(/^http(s)?:\/\//);
    const isFileName = payload.text.match(/\.\w{3}$/);

    if (isDataUrl || isUrl || isFileName) {
      const result = {};

      // return dummy cloud translations
      for (const targetLang of payload.targetLangs) {
        result[targetLang] = payload.text;
      }

      return Promise.resolve(<CloudTranslations>result);
    }

    payload.sourceLang = payload.sourceLang || 'de_DE';
    payload.targetLangs.filter(value => value !== payload.sourceLang);

    const {textWithPlaceholders, placeholderMap} = this.preprocessText(payload.text);
    const updatedPayload = {...payload, text: textWithPlaceholders};

    return new Promise<CloudTranslations>((resolve, reject) => {
      this.http.post<CloudTranslationResponse>(basePath, updatedPayload, { headers })
        .subscribe(
          (response) => {
            const translated = response.translation;
            const postProcessedTranslations: CloudTranslations = {};

            for (const lang in translated) {
              if (translated.hasOwnProperty(lang)) {
                const translatedText = translated[lang];
                postProcessedTranslations[lang] = this.postprocessText(translatedText, placeholderMap);
              }
            }

            resolve(postProcessedTranslations);
          },
          (error) => reject(error)
        );
    });
  }

  /**
   * Preprocesses text by replacing variables with placeholders. This way the variables are not translated.
   */
  private preprocessText(text: string): { textWithPlaceholders: string, placeholderMap: { [key: string]: string } } {
    const placeholderMap: { [key: string]: string } = {};
    let placeholderIndex = 0;
    const variableRegex = /{{.*?}}/g;
    const textWithPlaceholders = text.replace(variableRegex, (match) => {
      const placeholder = `__VAR${placeholderIndex}__`;
      placeholderMap[placeholder] = match;
      placeholderIndex++;
      return placeholder;
    });
    return { textWithPlaceholders, placeholderMap };
  }

  /**
   * Postprocesses text by replacing placeholders with variables, after translation is done.
   */
  private postprocessText(text: string, placeholderMap: { [key: string]: string }): string {
    let resultText = text;
    for (const placeholder in placeholderMap) {
      if (placeholderMap.hasOwnProperty(placeholder)) {
        const variable = placeholderMap[placeholder];
        const regex = new RegExp(placeholder, 'g');
        resultText = resultText.replace(regex, variable);
      }
    }
    return resultText;
  }
}
