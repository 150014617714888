import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {Questionnaire, Translation} from '@ngmedax/common-questionnaire-types';
import {CloudTranslationApiService, languages, flagIconCodeMap} from '@ngmedax/cloud-translator';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {KEYS} from '../../../translation-keys';
import {HttpErrorResponse} from '@angular/common/http';
import {ConfigService} from '@ngmedax/config';

// Hack to inject decorator declarations. Must occur before class declaration!
export interface QuestionnaireTranslationComponent extends Translatable {}

@Component({
  selector: 'app-questionnaire-translation',
  templateUrl: './questionnaire-translation.component.html',
  styleUrls: ['./questionnaire-translation.component.css'],
})
@Translatable({ scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS })
export class QuestionnaireTranslationComponent implements AfterViewInit {
  @Input() questionnaire: Questionnaire;
  @Input() visible: boolean = false;

  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateQuestionnaire: EventEmitter<Questionnaire> = new EventEmitter();

  public anchorLocale = 'de_DE';
  public backend = 'deepl';
  public selectedLanguages: string[] = [];
  public objectKeys = Object.keys;
  public languages: { [key: string]: string } = languages;

  public totalTranslations: number = 0;
  public completedTranslations: number = 0;
  public translationProgress: number = 0;
  public isTranslating: boolean = false;
  public charsToTranslate: number = 0;
  public estimatePrice: number = 0;

  public canUseDeepl = false;
  public canUseOpenai = false;

  public constructor(
    private translationService: TranslationService,
    private configService: ConfigService,
    private apiService: CloudTranslationApiService
  ) {
    this.canUseDeepl = this.configService.get('translation.cloud.provider.deepl') == true;
    this.canUseOpenai = this.configService.get('translation.cloud.provider.openai') == true;
    this.canUseOpenai && (this.backend = 'openai');
    this.canUseDeepl && (this.backend = 'deepl');
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      if (this.questionnaire) {
        const titleLanguages = Object.keys(this.questionnaire.meta.title);
        titleLanguages && (this.selectedLanguages = titleLanguages.filter(locale => this.languages[locale]));
        this.onUpdateCalculation();
      }
    }, 500);
  }

  public onBackendChange(backend: string) {
    this.backend = backend;
    this.onUpdateCalculation();
  }

  public onUpdateCalculation() {
    this.charsToTranslate = this.countTranslationChars(this.questionnaire) * (this.selectedLanguages.length - 1);
    this.estimatePrice = this.calculatePrice(this.charsToTranslate);
  }

  public toggleLanguage(code: string) {
    const index = this.selectedLanguages.indexOf(code);
    if (index >= 0) {
      this.selectedLanguages.splice(index, 1);
    } else {
      this.selectedLanguages.push(code);
    }
    this.onUpdateCalculation();
  }

  public async translateQuestionnaire() {
    if (!this.questionnaire || !this.selectedLanguages.length) {
      return;
    }

    try {
      this.totalTranslations = this.countTranslations(this.questionnaire);
      this.completedTranslations = 0;
      this.translationProgress = 0;
      this.isTranslating = true;

      await this.translate(this.questionnaire, this.anchorLocale);
      this.onUpdateQuestionnaire.emit(this.questionnaire);

      this.isTranslating = false;
      this.translationProgress = 100;
      alert(this._(this.KEYS.EDITOR.SUCCESSFULLY_TRANSLATED));
    } catch (error) {
      const msg = this.isHttpErrorResponse(error) ? error.error.message : (this.isError(error) ? error.message : 'Unknown error');
      alert(this._(this.KEYS.EDITOR.ERROR_AUTO_TRANSLATING) + ` > ${msg}`);
      console.error(error);
      this.isTranslating = false;
    }
  }

  public getFlagCode(code: string): string {
    const flagCodeMap: { [key: string]: string } = flagIconCodeMap;
    return flagCodeMap[code] || 'un';
  }

  public translateLanguage(language: string): string {
    return this.translationService ?
      this.translationService.translate({
        text: language,
        scope: ['cloudTranslatorModule', 'default']
      }) : language;
  }

  public hide() {
    this.onHide.emit();
  }

  private async translate(obj: any, anchorLocale = 'de_DE') {
    for (const key of Object.keys(obj)) {
      // translation object and should not be skipped
      if (this.isTranslation(obj[key]) && !this.shouldSkipTranslation(obj[key])) {
        const selectedLanguages = this.selectedLanguages.filter(locale => this.languages[locale]);

        const payload = {
          backend: this.backend,
          sourceLang: anchorLocale,
          targetLangs: selectedLanguages,
          text: obj[key][anchorLocale],
        };

        const translations = await this.apiService.translate(payload);
        const baseTranslation = obj[key][anchorLocale]
        obj[key] = Object.assign({}, {[anchorLocale]: baseTranslation}, translations);

        this.completedTranslations++;
        this.translationProgress = Math.round((this.completedTranslations / this.totalTranslations) * 100);
        continue;
      }

      // translation object and should be skipped
      if (this.isTranslation(obj[key]) && this.shouldSkipTranslation(obj[key])) {
        // we should clean up the translation object
        obj[key] = {[anchorLocale]: obj[key][anchorLocale]};
        continue;
      }

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        await this.translate(obj[key]);
        continue;
      }

      if (Array.isArray(obj[key])) {
        for (const item of obj[key]) {
          await this.translate(item);
        }
      }
    }
  }

  /**
   * Returns number of translations
   */
  private countTranslations(obj: any): number {
    let count = 0;
    for (const key of Object.keys(obj)) {
      if (this.isTranslation(obj[key]) && !this.shouldSkipTranslation(obj[key])) {
        count++;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        count += this.countTranslations(obj[key]);
      } else if (Array.isArray(obj[key])) {
        for (const item of obj[key]) {
          count += this.countTranslations(item);
        }
      }
    }
    return count;
  }

  /**
   * Returns number of chars to translate
   */
  private countTranslationChars(obj: any, anchorLocale = 'de_DE'): number {
    let count = 0;
    for (const key of Object.keys(obj)) {
      if (this.isTranslation(obj[key]) && !this.shouldSkipTranslation(obj[key])) {
        const chars = this.stripHtml(obj[key][anchorLocale]).length;
        count += chars;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        count += this.countTranslationChars(obj[key]);
      } else if (Array.isArray(obj[key])) {
        for (const item of obj[key]) {
          count += this.countTranslationChars(item);
        }
      }
    }
    return count;
  }

  /**
   * Calculates price for given char count
   */
  private calculatePrice(charCount: number): number {
    // deepl calculates with input chars = very precise price calculation
    if (this.backend === 'deepl') {
      const pricePer500kChars = 10; // 10 euro per 500.000 chars
      const pricePerChar = pricePer500kChars / 500000;
      const totalPrice = charCount * pricePerChar;
      return parseFloat(totalPrice.toFixed(2));
    }

    // openai calculates with tokens. we will estimate 4 chars for 1 token
    if (this.backend === 'openai') {
      const tokensPerChar = 1 / 4;
      const tokenCount = charCount * tokensPerChar;
      const inputTokens = tokenCount;
      const outputTokens = tokenCount;
      const inputCostPer1000Tokens = 0.03; // $0.03 per 1.000 tokens (input)
      const outputCostPer1000Tokens = 0.06; // $0.06 per 1.000 tokens (output)
      const inputCostUSD = (inputTokens / 1000) * inputCostPer1000Tokens;
      const outputCostUSD = (outputTokens / 1000) * outputCostPer1000Tokens;
      const totalCostUSD = inputCostUSD + outputCostUSD;
      const exchangeRate = 0.92; // approximate exchange rate
      const totalCostEuro = totalCostUSD * exchangeRate;
      return parseFloat(totalCostEuro.toFixed(2));
    }

    return 0;
  }

  /**
   * Checks if given value is a translation object
   * Skip translation for data urls, urls and file names
   */
  private isTranslation(value: any, anchorLocale = 'de_DE'): value is Translation {
    return  (
      value &&
      typeof value === 'object' &&
      typeof value[anchorLocale] === 'string' &&
      value[anchorLocale].length > 0
    );
  }

  /**
   * Checks if given value is an HttpErrorResponse
   */
  private isHttpErrorResponse(value: any): value is HttpErrorResponse {
    return value && value.error && value.status && value.statusText;
  }

  /**
   * Checks if given value is an Error
   */
  private isError(value: any): value is Error {
    return value && value.message;
  }

  private shouldSkipTranslation(value: any, anchorLocale = 'de_DE'): boolean {
    if (!value || typeof value !== 'object' || !value[anchorLocale]) {
      return true;
    }

    const isDataUrl = value[anchorLocale].match(/^data:image/);
    const isUrl = value[anchorLocale].match(/^http(s)?:\/\//);
    const isFileName = value[anchorLocale].match(/\.\w{3}$/);

    // skip translation for data urls, urls and file names
    return isDataUrl || isUrl || isFileName;
  }

  /**
   * Strips html from given string by using built-in browser functionality
   */
  private stripHtml(html: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
