<div class="patient-crud">
  <div class="d-flex">
    <h2 class="mb-4" [hidden]="hideForm" *ngIf="!patientForm.get('anonymous').value && !patientForm.get('pseudonymous').value">{{_(KEYS.CRUD.PATIENT)}}
    </h2>
    <h2 class="mb-4" [hidden]="hideForm" *ngIf="!!patientForm.get('anonymous').value">{{_(KEYS.CRUD.PATIENT_ANONYMOUS)}}</h2>
    <h2 class="mb-4" *ngIf="hideForm && !!patientForm.get('anonymous').value">{{_(KEYS.CRUD.PATIENT_ANONYMOUS)}}</h2>
    <h2 class="mb-4" [hidden]="hideForm" *ngIf="!!patientForm.get('pseudonymous').value">{{_(KEYS.CRUD.PATIENT_PSEUDONYMOUS)}}</h2>
    <h2 class="mb-4" *ngIf="hideForm && !!patientForm.get('pseudonymous').value">{{_(KEYS.CRUD.PATIENT_PSEUDONYMOUS)}}</h2>

    <ng-container *ngIf="hideForm && !patientForm.get('anonymous').value && !patientForm.get('pseudonymous').value">
      <h2 class="mb-4">{{_(KEYS.CRUD.PATIENT)}}: </h2>
      <div class="patient-data-small">
        {{patientForm.get('lastName').value}}, {{patientForm.get('firstName').value}} - *{{patientForm.get('birthDate').value}}
      </div>
    </ng-container>

    <div
      [ngClass]="{'alert-warning': !surveyLimit.reached, 'alert-danger': surveyLimit.reached}"
      *ngIf="surveyLimit.max"
    >
      <i class="fa fa-info-circle">&nbsp;</i>
      {{_(KEYS.CRUD.SURVEY_LIMIT_INFO, surveyLimit)}}
    </div>
  </div>

  <form [formGroup]="patientForm" *ngIf="patientForm.get('pseudonymous').value">
    <div class="form-group" class="group-gender">
      <label for="pseudoId">{{_(KEYS.CRUD.PSEUDO_ID)}}</label>
      <input
        class="form-control" id="pseudoId"
        [placeholder]="_(KEYS.CRUD.ENTER_PSEUDO_ID)"
        formControlName="pseudoId"
        name="pseudoId">
    </div>
  </form>

  <form [formGroup]="patientForm" [hidden]="hideForm || !!patientForm.get('anonymous').value || !!patientForm.get('pseudonymous').value">
    <input type="hidden" name="uid">

    <div class="form-group" class="group-gender">
      <label for="gender">{{_(KEYS.CRUD.GENDER)}}</label>
      <select class="form-control" id="gender" name="gender" formControlName="gender">
        <option value="male">{{_(KEYS.CRUD.MALE)}}</option>
        <option value="female">{{_(KEYS.CRUD.FEMALE)}}</option>
      </select>
    </div>

    <div class="form-group" class="group-first-name">
      <label for="firstName">{{_(KEYS.CRUD.FIRSTNAME)}}</label>
      <input
        class="form-control" id="firstName"
        [placeholder]="_(KEYS.CRUD.ENTER_FIRSTNAME)"
        formControlName="firstName"
        name="firstName">
    </div>

    <div class="form-group" class="group-last-name">
      <label for="lastName">{{_(KEYS.CRUD.LASTNAME)}}</label>
      <input
        class="form-control"
        id="lastName"
        [placeholder]="_(KEYS.CRUD.ENTER_LASTNAME)"
        formControlName="lastName"
        name="lastName">
    </div>

    <div class="form-group" class="group-birth-date">
      <label for="birthDate">{{_(KEYS.CRUD.BIRTHDATE)}}</label>
      <input
        class="form-control"
        id="birthDate"
        [placeholder]="getLocalDateFormat()"
        formControlName="birthDate"
        name="birthDate"
        [attr.data-mask]="getLocalDateFormatMask()"
        [attr.data-placeholder]="getLocalDateFormat()">
    </div>

    <div class="form-group group-zip-code">
      <label for="zipCode">{{_(KEYS.CRUD.ZIP_CODE)}}</label>
      <input
        class="form-control"
        id="zipCode"
        [placeholder]="_(KEYS.CRUD.ZIP_CODE)"
        formControlName="zipCode"
        name="zipCode">
    </div>

    <div class="form-group group-city">
      <label for="city">{{_(KEYS.CRUD.CITY)}}</label>
      <input
        class="form-control"
        id="city"
        [placeholder]="_(KEYS.CRUD.CITY)"
        formControlName="city"
        name="city">
    </div>

    <div class="form-group group-street">
      <label for="street">{{_(KEYS.CRUD.STREET)}}</label>
      <input
        class="form-control"
        id="street"
        [placeholder]="_(KEYS.CRUD.STREET)"
        formControlName="street"
        name="street">
    </div>

    <div class="form-group group-street-nr">
      <label for="streetNr">{{_(KEYS.CRUD.STREET_NR)}}</label>
      <input
        class="form-control"
        id="streetNr"
        [placeholder]="_(KEYS.CRUD.STREET_NR)"
        formControlName="streetNr"
        name="streetNr">
    </div>

    <div class="form-group" class="group-address">
      <label>{{_(KEYS.CRUD.ADDRESS_DEPRECATED)}}:&nbsp;</label>
      <span>{{patientForm.get('address').value}}</span>
      <hr>
    </div>

    <div class="form-group group-email">
      <label for="email">{{_(KEYS.CRUD.EMAIL)}}</label>
      <input
        class="form-control"
        id="email"
        [placeholder]="_(KEYS.CRUD.EMAIL)"
        formControlName="email"
        name="email">
    </div>

    <div class="form-group" class="group-location">
      <label for="location">{{_(KEYS.CRUD.LOCATION)}}</label>
      <input
        class="form-control"
        id="location"
        [placeholder]="_(KEYS.CRUD.ENTER_LOCATION)"
        formControlName="location"
        name="location">
    </div>

    <div class="form-group" class="group-pseudo-id">
      <label for="pseudoId">{{_(KEYS.CRUD.PSEUDO_ID)}}</label>
      <input
        class="form-control"
        id="pseudoId"
        [placeholder]="_(KEYS.CRUD.ENTER_PSEUDO_ID)"
        formControlName="pseudoId"
        name="pseudoId">
    </div>

    <div class="form-group" class="group-customer-nr">
      <label for="customerNr">{{_(KEYS.CRUD.ID)}}</label>
      <input
        class="form-control"
        id="customerNr"
        [placeholder]="_(KEYS.CRUD.ENTER_PATIENT_ID)"
        formControlName="customerNr"
        name="customerNr">
    </div>

    <div class="form-group" class="group-case-nr">
      <label for="caseNr">{{_(KEYS.CRUD.CASE_NUMBER)}}</label>
      <input
        class="form-control"
        id="caseNr"
        [placeholder]="_(KEYS.CRUD.ENTER_CASE_NUMBER)"
        formControlName="caseNr"
        name="caseNr">
    </div>

    <div class="form-group" class="group-state">
      <label for="state">{{_(KEYS.CRUD.STATUS)}}</label>
      <input
        class="form-control"
        id="state"
        [placeholder]="_(KEYS.CRUD.ENTER_STATUS)"
        formControlName="status"
        name="status"
      >
    </div>

    <div class="form-group group-language">
      <label for="locale">
        {{_(KEYS.CRUD.LANGUAGE)}}
        <i class="fa fa-flag" ngbTooltip="{{_(KEYS.CRUD.LANGUAGE)}}"></i>
      </label>
      <select class="form-control" id="locale" formControlName="locale">
        <ng-container *ngFor="let locale of objectKeys(languages)">
          <option [value]="locale">{{translateLanguage(languages[locale])}}</option>
        </ng-container>
      </select>
    </div>

    <div class="form-group" class="group-allow-mail" *ngIf="feature.patient.app.mail && isSurveyMailConfigured">
      <label for="allowMail">
        {{_(KEYS.CRUD.ALLOW_MAIL_OPTION)}}&nbsp;
        <i class="fa fa-info-circle" ngbTooltip="{{_(KEYS.CRUD.ALLOW_MAIL_TOOLTIP)}}"></i>
      </label>
      <select class="form-control" id="allowMail" formControlName="allowMail">
        <option value="true">{{_(KEYS.CRUD.YES)}}</option>
        <option value="false">{{_(KEYS.CRUD.NO)}}</option>
      </select>
    </div>

    <div class="form-group" class="group-prefill">
      <label for="prefill">
        {{_(KEYS.CRUD.PREFILL)}}&nbsp;
        <i class="fa fa-info-circle" ngbTooltip="{{_(KEYS.CRUD.PREFILL_TOOLTIP)}}"></i>
      </label>
      <select class="form-control" id="prefill" formControlName="prefill">
        <option value="yes">{{_(KEYS.CRUD.YES)}}</option>
        <option value="no">{{_(KEYS.CRUD.NO)}}</option>
        <option value="optional">{{_(KEYS.CRUD.OPTIONAL)}}</option>
      </select>
    </div>

    <div class="group-hr2"><hr></div>
  </form>

  <div
    *ngIf="(hideForm || patientForm.get('anonymous').value || patientForm.get('pseudonymous').value) && feature.patient.app.mail && isSurveyMailConfigured"
    [formGroup]="patientForm"
    style="width: 200px"
  >
    <div class="form-group">
      <label for="state">
        {{_(KEYS.CRUD.ALLOW_MAIL_OPTION)}}&nbsp;
        <i class="fa fa-info-circle" ngbTooltip="{{_(KEYS.CRUD.ALLOW_MAIL_TOOLTIP)}}"></i>
      </label>
      <select class="form-control" formControlName="allowMail">
        <option value="true">{{_(KEYS.CRUD.YES)}}</option>
        <option value="false" selected>{{_(KEYS.CRUD.NO)}}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="prefill">
        {{_(KEYS.CRUD.PREFILL)}}&nbsp;
        <i class="fa fa-info-circle" ngbTooltip="{{_(KEYS.CRUD.PREFILL_TOOLTIP)}}"></i>
      </label>
      <select class="form-control" id="prefill" formControlName="prefill">
        <option value="yes">{{_(KEYS.CRUD.YES)}}</option>
        <option value="no" selected>{{_(KEYS.CRUD.NO)}}</option>
        <option value="optional">{{_(KEYS.CRUD.OPTIONAL)}}</option>
      </select>
    </div>
    <div class="group-hr2"><hr></div>
  </div>

  <div class="bottom mt-2" [ngClass]="{'new-patient': !patientForm.get('uid').value}">

    <div class="form-group" class="group-questionnaires">
      <div class="mt-2">
        <app-questionnaire-select
          [enableGroups]="true"
          [selected]="selectedQuestionnaires"
          (selectedChange)="onQuestionnairesSelect($event)">
        </app-questionnaire-select>
      </div>
    </div>

    <div class="group-qr mt-2">
      <ng-container *ngIf="patientForm.get('uid').value && this.selectedQuestionnaires.length && !dirty">
        <label class="mb-3">{{_(KEYS.CRUD.ASSIGN)}}</label>
        <div class="btn-group ml-2">
          <button type="button" class="btn btn-sm btn-primary" [ngClass]="{active: qrMode == 1}" (click)="onToggleQr(1)">Mobil</button>
          <button type="button" class="btn btn-sm btn-primary" [ngClass]="{active: qrMode == 2}" (click)="onToggleQr(2)">Browser</button>
        </div>

        <button type="button" class="btn btn-sm btn-sx btn-primary ml-1" (click)="onPrintQr()"><i class="fa fa-print"></i></button>
        <button type="button" class="btn btn-sm btn-sx btn-primary ml-1" (click)="onOpenQr()"><i class="fa fa-window-maximize"></i></button>
        <br>

        <ng-container *ngIf="qrMode == 1">
          <div class="qr-wrap">
            <i class="fa fa-mobile qr-inner"></i>
            <qrcode [qrdata]="'mymedax|patient|' + patientForm.get('uid').value + '&locale=' + patientForm.get('locale').value || locale" [margin]="0" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
          </div>
          <br>
          <span class="txt-code">mymedax|patient|{{patientForm.get('uid').value}}</span>
        </ng-container>

        <ng-container *ngIf="qrMode == 2">
          <div class="qr-wrap">
            <i class="fa fa-globe qr-inner"></i>
            <qrcode [qrdata]="qrFillLink" [margin]="0" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <br>
</div>
<div [ngClass]="{'btn-footer': !overlayMode}">
  <button
    class="btn mr-2"
    ngbTooltip="{{_(KEYS.CRUD.ANONYMOUS_ALLOCATION)}}"
    [disabled]="patientForm.get('uid').value"
    *ngIf="!patientForm.get('uid').value && feature.patient.anonymous && !disableUserToggle"
    [ngClass]="{'btn-primary': !patientForm.get('anonymous').value, 'btn-success': !!patientForm.get('anonymous').value}"
    (click)="onToggleAnonymous()"
  >
    <i class="fa fa-user-secret"></i>
  </button>

  <button
    class="btn mr-2"
    ngbTooltip="{{_(KEYS.CRUD.PSEUDONYMOUS_ALLOCATION)}}"
    [disabled]="patientForm.get('uid').value"
    *ngIf="!patientForm.get('uid').value && feature.patient.anonymous && !disableUserToggle"
    [ngClass]="{'btn-primary': !patientForm.get('pseudonymous').value, 'btn-success': !!patientForm.get('pseudonymous').value}"
    (click)="onTogglePseudonymous()"
  >
    <i class="fa fa-user-o"></i>
  </button>

  <button
    id="saveBtn"
    class="btn btn-primary"
    [disabled]="(!hideForm && !patientForm.valid && !patientForm.get('anonymous').value && !patientForm.get('pseudonymous').value)"
    (click)="onSubmit()">
    <i class="fa fa-save"></i>
    {{_(KEYS.CRUD.SAVE_ASSIGN)}}
  </button>

  <span
    *ngIf="true"
    class="vl" >&nbsp;
  </span>

  <button
    class="btn btn-success"
    (click)="onCreateJumpInCode()"
    ngbTooltip="{{_(KEYS.CRUD.CREATE_JUMP_IN_CODE)}}"
    [disabled]="(this.selectedQuestionnaires.length && !dirty) ? null: true"
  >
    <i class="fa fa-keyboard-o"></i>
  </button>

  <span
    *ngIf="feature.patient.app.link.open || feature.patient.app.link.send || feature.patient.app.link.copy"
    class="vl" >&nbsp;
  </span>

  <button
    *ngIf="feature.patient.app.link.open"
    class="btn btn-success mr-2"
    (click)="onFillStart()"
    [disabled]="!patientForm.get('uid').value || !selectedQuestionnaires.length"
  >
    <i class="fa fa-edit"></i>
    {{_(KEYS.CRUD.FILL_OUT_DIRECTLY)}}
  </button>

  <button
    *ngIf="feature.patient.app.link.copy"
    class="btn btn-success mr-2"
    (click)="onFillCopyClipboard()"
    [disabled]="!patientForm.get('uid').value || !selectedQuestionnaires.length"
  >
    <i class="fa fa-copy"></i>
    {{_(KEYS.CRUD.COPY_LINK)}}
  </button>

  <button
    class="btn btn-success"
    (click)="onSendMail()"
    *ngIf="patientForm.get('uid').value && feature.patient.app.link.send && selectedQuestionnaires.length"
  >
    <i class="fa fa-envelope"></i>
    {{_(KEYS.CRUD.SEND_LINK)}}
  </button>

  <span class="vl" *ngIf="showCloseWinBtn">&nbsp;</span>

  <button class="btn btn-success" id="closeWinBtn" *ngIf="showCloseWinBtn">
    <i class="fa fa-close"></i> {{_(KEYS.CRUD.CLOSE_WINDOW)}}
  </button>
</div>
