import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {LayoutService} from '@ngmedax/layout';
import {CloudTranslationApiService} from './services/cloud-translation-api.service';
import {AuthUriService} from './services/auth-uri.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    AuthUriService,
    CloudTranslationApiService
  ]
})
export class CloudTranslatorModule {
  public constructor(private layoutService: LayoutService) {
  }
}
