/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  GRID: {
    QUESTIONNAIRE_SHEETS: 'Fragebogen-Vorlagen',
    SEARCH: 'Suche',
    TITLE: 'Titel',
    LANGUAGES: 'Sprachen',
    FILTER_GROUPS: 'Fachgruppen',
    AUTHOR: 'Autor',
    STATUS: 'Status',
    DRAFT: 'Entwurf',
    APPROVED: 'Freigegeben',
    QUESTIONNAIRES: 'Fragebögen',
    ID: 'Id',
    ACTIVE: 'Aktiv',
    CREATED_AT: 'Erstellt am',
    UPDATED_AT: 'Bearbeitet am',
    OPTIONS: 'Optionen',
    DOWNLOAD: 'Herunterladen',
    DUPLICATE: 'Duplizieren',
    CHANGE: 'Ändern',
    DELETE: 'Löschen',
    ENTRIES: 'Einträge',
    IMPORT: 'Importieren',
    EXPORT_ALL: 'Alle exportieren',
    SUCCESSFULLY_DUPLICATED_QUESTIONNAIRE: 'Der Fragebogen wurde erfolgreich dupliziert.',
    ERROR_DUPLICATING_QUESTIONNAIRE: 'Beim Duplizieren des Fragebogens ist ein Fehler aufgetreten!',
    QUESTION_DELETE_QUESTIONNAIRE: 'Sind sie sicher, dass sie diesen Fragebogen löschen möchten?: [[title]]',
    QUESTION_EXPORT_ALL_QUESTIONNAIRES: 'Sind sie sicher, dass sie alle Fragebögen exportieren möchten?',
    SUCCESSFULLY_DELETED_QUESTIONNAIRE: 'Der Fragebogen wurde erfolgreich gelöscht.',
    ERROR_DELETING_QUESTIONNAIRE: 'Beim Löschen des Fragebogens ist ein Fehler aufgetreten!',
    SUCCESSFULLY_IMPORTED_QUESTIONNAIRES: 'Der Import wurde erfolgreich abgeschlossen.',
    SUCCESSFULLY_EXPORTED_QUESTIONNAIRES: 'Der Export wurde erfolgreich abgeschlossen.',
    FAILED_TO_IMPORT_QUESTIONNAIRES: 'Beim Import ist ein Fehler aufgetreten!',
    FAILED_TO_EXPORT_QUESTIONNAIRES: 'Beim Export ist ein Fehler aufgetreten!',
    ERROR_LOADING_QUESTIONNAIRES: 'Beim Laden der Fragebögen ist ein Fehler aufgetreten!',
    ERROR_DOWNLOADING_QUESTIONNAIRE: 'Beim Herunterladen des Fragebogens ist ein Fehler aufgetreten!',
    ERROR_IMPORTING_QUESTIONNAIRE: 'Beim Importieren des Fragebogens ist ein Fehler aufgetreten!',
    ERROR_MISSING_MANDATORY_CONFIG: 'Die Konfiguration enthält keine API URL zum Abrufen von Fragebogen-Vorlagen!',
    ERROR_CANT_IMPORT_BECAUSE_OF_QUESTIONNAIRE_LIMIT: 'Der Import kann nicht durchgeführt werden, da dieser die'
      + ' von der Lizenz erlaubte Anzahl von Fragebögen überschreiten würde.',
    CONFIG_ERROR: 'Konfigurationsfehler',
    PROCESSING: 'Verarbeitung läuft...'
  }
};
