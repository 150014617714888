<div class="qa-translation-overlay" *ngIf="visible">
  <div class="card bg-light mb-3">
    <div class="card-header">
      <i class="fa fa-flag"></i> {{_(KEYS.EDITOR.AUTOMATIC_QUESTIONNAIRE_TRANSLATION)}}
      <span *ngIf="charsToTranslate" class="badge badge-secondary ml-1">{{charsToTranslate}} {{_(KEYS.EDITOR.CHARS)}} | ~{{estimatePrice}} €</span>

      <button class="close" (click)="hide()">
        <i class="fa fa-close"></i>
      </button>
    </div>
    <div class="card-body">
      <div *ngIf="isTranslating" class="progress mb-3">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width.%]="translationProgress"
          [attr.aria-valuenow]="translationProgress"
          aria-valuemin="0"
          aria-valuemax="100"
        >
        </div>
        <span class="progress-number">{{ translationProgress }}%</span>
      </div>

      <div class="language-list">
        <div class="language-item" *ngFor="let code of objectKeys(languages)">
          <label>
            <input
              type="checkbox"
              [checked]="selectedLanguages.includes(code)"
              (change)="toggleLanguage(code)"
              [disabled]="isTranslating || code === 'de_DE'"
            />
            &nbsp;
            <span class="flag-icon flag-icon-{{getFlagCode(code)}}"></span>
            {{translateLanguage(languages[code])}}
          </label>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="translation-panel btn-group ml-2" role="group">
        <button class="btn btn-success" (click)="translateQuestionnaire()" [disabled]="isTranslating || selectedLanguages.length < 2">
          <i class="fa fa-upload"></i> {{_(KEYS.EDITOR.TRANSLATE_QUESTIONNAIRE)}}
        </button>
        <button *ngIf="canUseDeepl" type="button" class="btn btn-secondary"
                [ngClass]="{'btn-primary': backend === 'deepl'}"
                (click)="onBackendChange('deepl')">
          <i class="fa fa-square-o" aria-hidden="true" *ngIf="backend !== 'deepl'"></i>
          <i class="fa fa-check-square-o" aria-hidden="true" *ngIf="backend === 'deepl'"></i>
          &nbsp;<i class="fa fa-language"></i> DeepL
        </button>
        <button *ngIf="canUseOpenai" type="button" class="btn btn-secondary"
                [ngClass]="{'btn-primary': backend === 'openai'}"
                (click)="onBackendChange('openai')">
          <i class="fa fa-square-o" aria-hidden="true" *ngIf="backend !== 'openai'"></i>
          <i class="fa fa-check-square-o" aria-hidden="true" *ngIf="backend === 'openai'"></i>
          &nbsp;<i class="fa fa-lightbulb-o"></i> OpenAI
        </button>
      </div>

      <button class="btn btn-secondary ml-1 float-right" (click)="hide()">
        <i class="fa fa-close"></i> {{_(KEYS.EDITOR.CLOSE)}}
      </button>
    </div>
  </div>
</div>
