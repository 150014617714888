export interface CloudTranslationPayload {
  backend: string;
  sourceLang?: string;
  targetLangs: string[];
  text: string;
}

export interface CloudTranslationResponse {
  success: boolean;
  translation: CloudTranslations;
  message?: string;
}

export interface CloudTranslations {
  [key: string]: string;
}

export const flagIconCodeMap: { [key: string]: string } = {
  'ar_SA': 'sa',
  'bg_BG': 'bg',
  'cs_CZ': 'cz',
  'da_DK': 'dk',
  'de_DE': 'de',
  'el_GR': 'gr',
  'en_GB': 'gb',
  'en_US': 'us',
  'es_ES': 'es',
  'et_EE': 'ee',
  'fi_FI': 'fi',
  'fr_FR': 'fr',
  'hu_HU': 'hu',
  'id_ID': 'id',
  'it_IT': 'it',
  'ja_JP': 'jp',
  'ko_KR': 'kr',
  'lt_LT': 'lt',
  'lv_LV': 'lv',
  'nb_NO': 'no',
  'nl_NL': 'nl',
  'pl_PL': 'pl',
  'pt_PT': 'pt',
  'pt_BR': 'br',
  'ro_RO': 'ro',
  'ru_RU': 'ru',
  'sk_SK': 'sk',
  'sl_SI': 'si',
  'sv_SE': 'se',
  'tr_TR': 'tr',
  'uk_UA': 'ua',
  'zh_CN': 'cn',
  'zh_TW': 'tw',
};

export const languages: { [key: string]: string } = {
  'de_DE': 'Deutsch',
  'ar_SA': 'Arabisch',
  'bg_BG': 'Bulgarisch',
  'cs_CZ': 'Tschechisch',
  'da_DK': 'Dänisch',
  'el_GR': 'Griechisch',
  'en_GB': 'Englisch (Britisch)',
  'en_US': 'Englisch (Amerikanisch)',
  'es_ES': 'Spanisch',
  'et_EE': 'Estnisch',
  'fi_FI': 'Finnisch',
  'fr_FR': 'Französisch',
  'hu_HU': 'Ungarisch',
  'id_ID': 'Indonesisch',
  'it_IT': 'Italienisch',
  'ja_JP': 'Japanisch',
  'ko_KR': 'Koreanisch',
  'lt_LT': 'Litauisch',
  'lv_LV': 'Lettisch',
  'nb_NO': 'Norwegisch Bokmål',
  'nl_NL': 'Niederländisch',
  'pl_PL': 'Polnisch',
  'pt_PT': 'Portugiesisch (Portugal)',
  'pt_BR': 'Portugiesisch (Brasilien)',
  'ro_RO': 'Rumänisch',
  'ru_RU': 'Russisch',
  'sk_SK': 'Slowakisch',
  'sl_SI': 'Slowenisch',
  'sv_SE': 'Schwedisch',
  'tr_TR': 'Türkisch',
  'uk_UA': 'Ukrainisch',
  'zh_CN': 'Chinesisch (Vereinfacht)',
  'zh_TW': 'Chinesisch (Traditionell)',
};
